/* global envision:false, svDocReady:false */
/**
 * Copyright (C) SiteVision AB 2002-2024, all rights reserved
 *
 */
import $ from '@sv/jquery';
import '../util/slideShow/sv-imgSlideshow'; // Used when content list is used in carousel mode

$('.sv-contentlist-portlet').each((a, portlet) => {
  const $portlet = $(portlet);
  const $envTabs = $portlet.find('.env-tabs');
  const $legacyTab = $portlet.find('.sv-tab');

  if ($envTabs.length) {
    svDocReady(() => {
      envision.tabs($envTabs[0]);
    });
  }

  if ($legacyTab.length) {
    import(/* webpackChunkName: "tab-plugins" */ './tabPlugins');
  }
});
